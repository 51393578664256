import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import RulesContent from "../components/rulesContent";
import i18nMessages from '../i18n/en.json';
import 'intl/locale-data/jsonp/en';
import PageWrapper from "../components/pageWrapper";

const Rules = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);

    return (
        <PageWrapper location={props.location} lang="en" i18nMessages={i18nMessages}>
            <RulesContent location={props.location} langUrl="" />
        </PageWrapper>
    )
}

export default Rules

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`