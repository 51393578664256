import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ad from "../../static/cam4/cam4-250x250-1.gif";


const RulesContent = (props) => {
    const totalUnread = useSelector(state => state.totalUnread);
    const plusMember = useSelector(state => state.plusMember);

    return (
       <div className="pageWrapper page textPage">
            <SEO 
                title={totalUnread == 0 ? props.intl.formatMessage({ id: 'rules' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
                description={props.intl.formatMessage({ id: 'newsSeoDesc' })}
                pagePath="/rules"
            />

            <h1><FormattedMessage id="rules" /></h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>
            <br />

            <div className="aboutPage">
                <p><FormattedMessage id="theFollowingRules" /> <FormattedMessage id="toAvoidLimiting" /></p>
                {/*}
                    {!plusMember &&
                        <div className="inlineAdWrapper">
                            <a href="https://track.cam4tracking.com/a8877e92-16f2-4a6e-9662-1c2a43d1398a?SiteID={SiteID}&VAR8=200679&VAR9=dinchat_250x250" 
                                    target="_blank">
                                <img className="adMedium" src={ad} alt="" />
                            </a>
                        </div>
                    }
                {*/}
                    <ul className="rulesList">
                        <li><FormattedMessage id="ageLimit" /></li>
                        <li><FormattedMessage id="linksToPaidSites" /></li>
                        <li><FormattedMessage id="allSpamForbidden" /></li>
                        <li><FormattedMessage id="noSexualContent" /></li>
                        <li><FormattedMessage id="everythingAgainstLaw" /></li>
                        <li><FormattedMessage id="noHarassment" /></li>
                        <li><FormattedMessage id="allUsersResponsible" /></li>
                        <li><FormattedMessage id="roomsMoreRules" /></li>
                    </ul>
                    <br />
                    <p><FormattedMessage id="ifYouSeeAnybody" /> <a href="mailto:support@dinchat.com"><FormattedMessage id="contactUsHere" /></a>.</p>

                    
            </div>


        </div>
    )
}

export default injectIntl(RulesContent);